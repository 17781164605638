import Layout from "../components/layout";
import React from "react";

const Utility = () => (

    <Layout>
        <section className="section-utility">
            <div className="section-6 wf-section">
                <div data-w-id="fee6c11f-454d-a31b-c936-e96f978ddd39" className="v3-1-hero w-container">
                    <div className="home-announcement-container">
                        <div className="div-block-101">
                            <div className="text-block-73">NEW</div>
                            <img src="/images/6243040d301c232fbcd220f6_Group 2121.svg" loading="lazy" alt="" className="image-95"/>
                        </div>
                        <a data-event-category="Home Announcement Link" data-event-action="Click" data-event-label="Roast My Finance Stack"
                           href="https://www.rebanknow.com/roast-my-finance-stack" className="v3-banner-link w-inline-block">
                            <div className="v3-link-text-small">Roast My Finance Stack</div>
                            <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                                 className="v3-benefit-link-arrow"/>
                        </a>
                    </div>
                    <h1 id="v3-hero-heading-original" className="v3-1-hero-heading">Healthy Living. Happy Living<br/></h1>
                    <div className="v3-1-hero-text">Seamless access to your medical records through a cloud based system
                        <br/> Anytime…Anywhere! <br/>
                        No need to carry medical files for every doctor visit. It's already there!
                    </div>
                    <img src="/images/stethescope.svg" loading="lazy" alt="" className="image-75"/>
                    <img src="/images/6228d900cf0cdd5bfd7b31c8_right-hero-img.svg" loading="lazy" alt="" className="image-76"/>
                    <div className="div-block-87">
                    </div>
                </div>
            </div>
            <div className="section-12 wf-section">
                <div className="v3-main w-container">
                    <div className="v3-benefit-row">
                        <div className="div-block-67">
                            <h2 className="v3-benefit-heading">Take the charge of your health in your hands.</h2>
                            <div className="v3-link-text">
                                The DD Zone Health app is a personal health record app available which helps to store, record and share the personal medical data with any doctors or healthcare providers through the internet.
                                The application helps in storing and tracking the weight, blood pressure, sugar level and other health-related information.
                            </div>
                        </div>
                        <div className="div-block-90">
                            <img src="/images/healthapp.svg" loading="lazy" data-w-id="71287bbe-10c2-ccff-15dd-a466ad9a6161" alt="" className="v3-1-benefit-img-1"/>
                            {/*<img src="/images/6214f37bbec7b23bce0ad03b_opportunities-back.svg" loading="lazy" alt="Opportunities backdrop" className="image-61"/>*/}
                        </div>
                    </div>
                    <div className="v3-benefit-row">
                        <div className="div-block-90">
                            <img src="/images/mobilehealth.svg" loading="lazy" data-w-id="b1ee4da0-6177-00ac-9762-5de9c7a6f914" alt="Drone carrying cash across borders" className="v3-benefit-img-2"/>
                            {/*<img src="/images/6214d761fa850257971b09a4_drone-clouds.svg" loading="lazy" alt="Clouds" className="image-62"/>*/}
                        </div>
                        <div className="div-block-67">
                            <h2 className="v3-benefit-heading">
                                The DD Zone Health app: free, simple, priceless</h2>
                            <div className="v3-link-text">
                                You’ll find the information and inspiration you need to reach your goals—all in one place.
                                Together, we can make health and wellness features more accessible to more people.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-6 wf-section">
                <div data-w-id="fee6c11f-454d-a31b-c936-e96f978ddd39" className="v3-1-hero w-container">
                    <div className="home-announcement-container">
                        <div className="div-block-101">
                            <div className="text-block-73">NEW</div>
                            <img src="/images/6243040d301c232fbcd220f6_Group 2121.svg" loading="lazy" alt="" className="image-95"/>
                        </div>
                        <a data-event-category="Home Announcement Link" data-event-action="Click" data-event-label="Roast My Finance Stack"
                           href="https://www.rebanknow.com/roast-my-finance-stack" className="v3-banner-link w-inline-block">
                            <div className="v3-link-text-small">Roast My Finance Stack</div>
                            <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                                 className="v3-benefit-link-arrow"/>
                        </a>
                    </div>
                    <h1 id="v3-hero-heading-original" className="v3-1-hero-heading">Go Paperless. Go Digital<br/></h1>
                    <div className="v3-1-hero-text">No need to carry physical cards anymore.<br/>
                        Create and customize stylish digital business cards and share them with anyone, near or far.
                    </div>
                    <img src="/images/6228da468c3512cfc626c2b9_left-hero-img.svg" loading="lazy" alt="" className="image-75"/>
                    <img src="/images/6228d900cf0cdd5bfd7b31c8_right-hero-img.svg" loading="lazy" alt="" className="image-76"/>
                    <div className="div-block-87">
                    </div>
                </div>
            </div>
            <div className="section-12 wf-section">
                <div className="v3-main w-container">
                    <div className="v3-benefit-row">
                        <div className="div-block-67">
                            <h2 className="v3-benefit-heading">Customize your card to match your brand.</h2>
                            <div className="v3-link-text">
                                DD Zone’s digital business cards are free, completely customizable, and you can share your card  with anyone—no app is required to receive a card.
                                You can create multiple cards with different information on each card. Make a card for work contacts, one for clients or customers, and one for your friends.
                                Using a digital business card means you can update your information with a single tap, so you don’t need to go through ordering and shipping new cards.
                            </div>
                        </div>
                        <div className="div-block-90">
                            <img src="/images/vcard.svg" loading="lazy" data-w-id="71287bbe-10c2-ccff-15dd-a466ad9a6161" alt="" className="v3-1-benefit-img-1"/>
                            <img src="/images/6214f37bbec7b23bce0ad03b_opportunities-back.svg" loading="lazy" alt="Opportunities backdrop" className="image-61"/>
                        </div>
                    </div>
                    <div className="v3-benefit-row">
                        <div className="div-block-90">
                            <img src="/images/business-card-icon.svg" loading="lazy" data-w-id="b1ee4da0-6177-00ac-9762-5de9c7a6f914" alt="Drone carrying cash across borders" className="v3-benefit-img-2"/>
                            {/*<img src="/images/6214d761fa850257971b09a4_drone-clouds.svg" loading="lazy" alt="Clouds" className="image-62"/>*/}
                        </div>
                        <div className="div-block-67">
                            <h2 className="v3-benefit-heading">
                                The best contactless business card.</h2>
                            <div className="v3-link-text">
                                Unfortunately, paper business cards typically end up forgotten in a pocket or briefcase, even if the contact is interested in building a professional relationship.
                                Even worse, if you forget to bring your business cards to a networking event, you’re out of luck when it comes time to swap contact information with a prospective connection.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
);

export default Utility;
